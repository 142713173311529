<template>
  <div>
    <div class="push-menu-layer">
      <div class="nav-justified">
        <div class="scroll-gradient">
          <div class="scroll-area-lg">
            <VuePerfectScrollbar class="scrollbar-container">
              <div class="vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                <template v-if="isPushMessage">
                  <template v-for="(pushMessage, idx) in pushMessages" >
                    <div v-if="pushMessages.length > 0"
                      :key="idx"
                      class="vertical-timeline-item vertical-timeline-element">
                      <div>
                        <span class="vertical-timeline-element-icon bounce-in">
                          <i class="badge badge-dot badge-dot-xl badge-primary"></i>
                        </span>
                        <div class="vertical-timeline-element-content bounce-in">
                          <span class="deletePush" @click="deletePush(pushMessage)"><i class="material-icons">close</i></span>
                          <h4 class="timeline-title" @click="goPushPage(pushMessage.route)">{{pushMessage.title}} </h4>
                          <p v-html="pushMessage.content" @click="goPushPage(pushMessage.route)"></p>
                          <p v-html="replaceDate(pushMessage.regDt)" @click="goPushPage(pushMessage.route)"></p>
                          <span class="vertical-timeline-element-date"></span>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div class="vertical-timeline-item vertical-timeline-element">
                    <div>
                      <span class="vertical-timeline-element-icon bounce-in">
                        <i class="badge badge-dot badge-dot-xl badge-danger"></i>
                      </span>
                      <div class="vertical-timeline-element-content bounce-in">
                        <h4 class="timeline-title">{{$language('알림이 없습니다.')}}</h4>
                        <span class="vertical-timeline-element-date"></span>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </VuePerfectScrollbar>
          </div>
        </div>
      </div>
    </div>
    <!-- 버튼 영역 -->
    <div class="search-box-btn">
      <q-btn-group outline >
        <c-btn label="알림 비우기" icon="delete_forever" color="red-5" @btnClicked="deletePushAll" />
      </q-btn-group>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import VuePerfectScrollbar from "vue-perfect-scrollbar";
export default {
  name: 'push-pop',
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      pushMessages: [],
      pushUrl: '',
    };
  },
  computed: {
    pushMessageCntTotal() {
      return this.pushMessages && this.pushMessages.length > 0 ?
        this.pushMessages.length : 0
    },
    isPushMessage() {
      return this.pushMessageCntTotal > 0
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.pushUrl = selectConfig.sys.push.history.list.url;
      this.getPushMessage();
    },
    getPushMessage() {
      this.$http.url = this.pushUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        userId: this.$store.getters.user.userId
      };
      this.$http.request((_result) => {
        this.pushMessages = _result.data
      },);
    },
    goPushPage(_route) {
      this.$router.push(_route);
      this.$emit('closePopup');
    },
    replaceDate(_date) {
      return _date.replace('.0', '')
    },
    deletePush(_push) {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(transactionConfig.sys.push.history.delete.url, _push.pushMessageId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.getPushMessage();
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    deletePushAll() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '전부 삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(transactionConfig.sys.push.history.user.url, this.$store.getters.user.userId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    }
  }
};
</script>


<style lang="sass">
.push-menu-layer
  padding-bottom: 50px
  .nav-justified
    padding: 10px
  .vertical-timeline-element-content
    p 
      font-size: 1.1em !important
    .timeline-title
      font-size: 1.25em !important
  .deletePush
    width: 40px
    height: 40px
    margin: 0 auto
    float: right
    padding-right: 7px
    i 
      font-size: 1.2em
      color: red
      font-weight: 600
</style>